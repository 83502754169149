<template>
  <div style="padding: 0 15px">
    <div style="padding:15px 0;">
      You can download <span v-if="landing.advanced.selected_langs.length>1">and upload</span> the file with translations. <br>
      <span v-if="landing.advanced.selected_langs.length>1">Translations will be added to all sections of your application.</span>
      <span v-else>If you want to add a translation, then activate the desired languages first</span>
    </div>
    <v-btn
      @click="downloadLangs"
      color="primary"
      outlined
      small
      style="margin-right: 15px"
      ><v-icon>mdi-download</v-icon>Download language file</v-btn
    >
    <v-btn
      color="primary"
      @click="changeShowUpload"
      outlined
      small
      v-if="landing.advanced.selected_langs.length > 1"
      ><v-icon>mdi-upload</v-icon>Upload</v-btn
    >
    <v-dialog v-model="showUpload" max-width="400px" @click:outside="closeUpload">
      <v-card>
        <v-icon
            style="position: absolute; top: 5px; right: 5px"
            @click="closeUpload"
            small
        >mdi-close</v-icon
        >
        <v-card-title>Choose language</v-card-title>
        <v-card-text style="padding-top:15px;">
          <v-select
            placeholder="Choose lang for upload"
            :items="langsForUpload"
            :error-messages="errorMessage"
            :error="error"
            item-value="value"
            item-text="name"
            outlined
            dense
            v-model="selectedLang"
          >
            <template v-slot:selection="{ item }">
                <img :src="item.flag" style="width:30px;height:20px;margin-right:10px;" alt=""> {{ item.name }}
            </template>
            <template v-slot:item="{ item }">
              <img :src="item.flag" style="width:30px;height:20px;margin-right:10px;" alt=""> {{ item.name }}
            </template>
          </v-select>
          <v-file-input
            accept="text/csv"
            outlined
            dense
            ref="pdfUpload"
            placeholder="Choose file"
            prepend-inner-icon="mdi-file"
            prepend-icon=""
            :error="errorFile"
            :error-messages="errorFileMessage"
            @change="onFileChange"
          ></v-file-input>
        </v-card-text>
        <v-card-actions
          style="display: flex; justify-content: flex-end; padding: 15px 20px"
        >
          <v-btn color="primary" @click="uploadBranding" outlined small
            >Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "LangsJson",
  data() {
    return {
      textArr: [],
      checkArr: [],
      showUpload: false,
      selectedLang: "",
      wordsArr: [],
      error: false,
      errorFileMessage:"",
      errorFile:false,
      errorMessage: "",
      uploadError:"",
      fileUpload:false,
    };
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("events", ["eventCategories"]),
    ...mapState("products", ["categories"]),
    ...mapState("points", ["mapDataList"]),
    ...mapState("trails", ["trailList"]),
    langsForUpload() {
      return this.landing.advanced.langs.filter((x) => x.value !== "en" && x.active);
    },
  },
  methods: {
    ...mapActions("events", [
      "getEventCategories",
      "editEventCategory",
      "editEvent",
    ]),
    ...mapActions("products", [
      "getCategoriesList",
      "editCategory",
      "editSubCategory",
      "editProduct",
    ]),
    ...mapActions("points", ["getMapData", "editPointCategory", "editPoint"]),
    ...mapActions("trails", ["getTrailList", "editTrail", "editTrailPoint"]),
    closeUpload(){
      this.showUpload = false;
    },
    uploadBranding() {
      this.errorFileMessage = "";
      this.errorFile = false;
      this.errorMessage = "";
      this.error = false;
      if (!this.selectedLang) {
        this.errorMessage = "Choose language";
        this.error = true;
        return;
      }
      if(!this.fileUpload){
        this.errorFileMessage = "Choose file";
        this.errorFile = true;
        return;
      }
      this.uploadLangsBranding(this.landing.branding);
      this.uploadLangsAdvanced(this.landing.advanced);
      this.uploadLangsReviews(this.landing.reviews);
      this.uploadLangsOrderForm(this.landing.order_form);
      this.uploadLangsOrder(this.landing.order);
      this.uploadLangsMenu(this.landing.menu);
    },
    checkUpload(string) {
      const candidate = this.wordsArr.find((x) => x[0] === string);
      if (candidate) {
        return candidate[1] ? candidate[1] : string;
      } else {
        return string;
      }
    },
    uploadLangsBranding(branding) {
      /*BOTTOM MENU*/
      if (branding.bottomMenu.active) {
        for (let i = 0; i < branding.bottomMenu.items.length; i++) {
          const bottomMenuItem = branding.bottomMenu.items[i];
          bottomMenuItem.text[this.selectedLang] = this.checkUpload(
            bottomMenuItem.text["en"]
          );
        }
      }
      /*FOOTER TEXT*/
      branding.footer_text[this.selectedLang] = this.checkUpload(
        branding.footer_text["en"]
      );
      branding.footer_title[this.selectedLang] = this.checkUpload(
        branding.footer_title["en"]
      );
      branding.title_no_auth[this.selectedLang] = this.checkUpload(
        branding.title_no_auth["en"]
      );
      branding.title[this.selectedLang] = this.checkUpload(
        branding.title["en"]
      );
      /* SLIDER */
      if (this.landing.advanced.global.active_slider) {
        for (let i = 0; i < branding.slider["en"].length; i++) {
          const sliderGroup = branding.slider["en"][i];
          branding.slider[this.selectedLang][i].title = this.checkUpload(
            sliderGroup.title
          );
          for (let index = 0; index < sliderGroup.slides.length; index++) {
            branding.slider[this.selectedLang][i].slides[index].text =
              this.checkUpload(sliderGroup.slides[i].text);
            branding.slider[this.selectedLang][i].slides[index].text_link =
              this.checkUpload(sliderGroup.slides[i].text_link);
          }
        }
      }
      this.$store.commit("landing/setBranding", branding);
    },
    uploadLangsAdvanced(advanced) {
      advanced.global.cartPayments.text[this.selectedLang] = this.checkUpload(
        advanced.global.cartPayments.text["en"]
      );
      advanced.global.page_404[this.selectedLang].content = this.checkUpload(
        advanced.global.page_404["en"].content
      );
      advanced.global.underConstructor.text[this.selectedLang] =
        this.checkUpload(advanced.global.underConstructor.text["en"]);
      this.$store.commit("landing/setAdvanced", advanced);
    },
    uploadLangsReviews(reviews) {
      const reviewsPro = reviews["en"];
      reviews[this.selectedLang].btn_name = this.checkUpload(
        reviewsPro.btn_name
      );
      reviews[this.selectedLang].header_text = this.checkUpload(
        reviewsPro.header_text
      );

      for (let i = 0; i < reviews[this.selectedLang].pro.length; i++) {
        reviews[this.selectedLang].pro[i].header = this.checkUpload(
          reviewsPro.pro[i].header
        );
        for (
          let index = 0;
          index < reviews[this.selectedLang].pro[i].questions.length;
          index++
        ) {
          reviews[this.selectedLang].pro[i].header = this.checkUpload(
            reviewsPro.pro[i].header
          );
          reviews[this.selectedLang].pro[i].questions[index].name =
            this.checkUpload(reviewsPro.pro[i].questions[index].name);
        }
      }

      for (let i = 0; i < reviews[this.selectedLang].questions.length; i++) {
        reviews[this.selectedLang].questions[i].name = this.checkUpload(
          reviewsPro.questions[i].name
        );
      }

      for (
        let i = 0;
        i < reviews[this.selectedLang].text_questions.length;
        i++
      ) {
        reviews[this.selectedLang].text_questions[i].name = this.checkUpload(
          reviewsPro.text_questions[i].name
        );
      }
      reviews.success_page[this.selectedLang].content = this.checkUpload(
        reviews.success_page["en"].content
      );
      reviews.success_page[this.selectedLang].second_content = this.checkUpload(
        reviews.success_page["en"].second_content
      );
      reviews.unsuccess_page[this.selectedLang].content = this.checkUpload(
        reviews.unsuccess_page["en"].content
      );
      reviews.unsuccess_page[this.selectedLang].second_content =
        this.checkUpload(reviews.unsuccess_page["en"].second_content);
      this.$store.commit("landing/setReviews", reviews);
    },
    uploadLangsOrderForm(orderForm) {
      const form = orderForm.content["en"];
      const formLang = orderForm.content[this.selectedLang];
      formLang.btn_name = this.checkUpload(form.btn_name);
      formLang.btn_second_name = this.checkUpload(form.btn_second_name);
      formLang.email_guest = this.checkUpload(form.email_guest);
      formLang.email_resident = this.checkUpload(form.email_resident);
      formLang.emptyCart = this.checkUpload(form.emptyCart);
      formLang.guest_phone = this.checkUpload(form.guest_phone);
      formLang.guest_special_request = this.checkUpload(
        form.guest_special_request
      );
      formLang.hotel_first_name_input = this.checkUpload(
        form.hotel_first_name_input
      );
      formLang.hotel_input = this.checkUpload(form.hotel_input);
      formLang.hotel_last_name_input = this.checkUpload(
        form.hotel_last_name_input
      );
      formLang.hotel_phone_input = this.checkUpload(form.hotel_phone_input);
      formLang.message_header = this.checkUpload(form.message_header);
      formLang.message_header_guest = this.checkUpload(
        form.message_header_guest
      );
      formLang.subject = this.checkUpload(form.subject);
      formLang.subject_guest = this.checkUpload(form.subject_guest);
      formLang.title = this.checkUpload(form.title);
      for (let i = 0; i < formLang.payment_methods_guest.length; i++) {
        formLang.payment_methods_guest[i].name = this.checkUpload(
          form.payment_methods_guest[i].name
        );
      }
      for (let i = 0; i < formLang.payment_methods_resident.length; i++) {
        formLang.payment_methods_resident[i].name = this.checkUpload(
          form.payment_methods_resident[i].name
        );
      }
      this.$store.commit("landing/setOrderForm", orderForm);
    },
    uploadLangsOrder(order) {
      order.success_page[this.selectedLang].content = this.checkUpload(
        order.success_page["en"].content
      );
      order.success_page[this.selectedLang].second_content = this.checkUpload(
        order.success_page["en"].second_content
      );
      order.success_page[this.selectedLang].table_content = this.checkUpload(
        order.success_page["en"].table_content
      );
      this.$store.commit("landing/setOrder", order);
    },
    async uploadLangsMenu(menu) {
      for (let i = 0; i < menu.list.length; i++) {
        menu.list[i].text[this.selectedLang] = this.checkUpload(
          menu.list[i].text["en"]
        );
        menu.list[i].content[this.selectedLang] = JSON.parse(
          JSON.stringify(menu.list[i].content["en"])
        );
        const contentDefault = menu.list[i].content["en"];
        const contentLang = menu.list[i].content[this.selectedLang];
        if (menu.list[i].type === 1) {
          this.uploadLangTypeHtml(contentDefault[0], contentLang[0]);
        } else if (menu.list[i].type === 4) {
          this.uploadLangTypeListing(contentDefault[3], contentLang[3]);
        } else if (menu.list[i].type === 5) {
          await this.uploadLangSubMenu(contentDefault[4], contentLang[4]);
        } else if (menu.list[i].type === 6) {
          this.uploadLangTypeFaq(contentDefault[5], contentLang[5]);
        } else if (menu.list[i].type === 7) {
          this.uploadLangTypeContact(contentDefault[6], contentLang[6]);
        } else if (menu.list[i].type === 8) {
          await this.uploadLangTypeEvents(contentDefault[7], contentLang[7]);
        } else if (menu.list[i].type === 9) {
          await this.uploadLangTypeProducts(
            menu.list[i],
            contentDefault[8],
            contentLang[8]
          );
        } else if (menu.list[i].type === 10) {
          this.uploadLangWeather(contentDefault[9], contentLang[9]);
        } else if (menu.list[i].type === 11) {
          await this.uploadLangTypeMap(
            menu.list[i],
            contentDefault[10],
            contentLang[10]
          );
        } else if (menu.list[i].type === 12) {
          this.uploadLangTypeDynamic(contentDefault[11], contentLang[11]);
        } else if (menu.list[i].type === 13) {
          this.uploadLangTypeGallery(contentDefault[12], contentLang[12]);
        } else if (menu.list[i].type === 14) {
          await this.uploadLangTypeTrails(menu.list[i]);
        }
      }
      this.$store.commit("landing/setMenu", menu);
    },
    async uploadLangSubMenu(contentDefault, contentLang) {
      if (contentLang.active_slider) {
        this.addToText(contentDefault.slider, contentLang.slider);
      }
      for (let i = 0; i < contentLang.content.menu.length; i++) {
        const subMenuItemDefault = contentDefault.content.menu[i];
        const subMenuItemLang = contentLang.content.menu[i];
        subMenuItemLang.name = this.checkUpload(subMenuItemDefault.name);

        if (subMenuItemLang.type === 1) {
          this.uploadLangTypeHtml(
            subMenuItemDefault.content[0],
            subMenuItemLang.content[0]
          );
        }
        if (subMenuItemLang.type === 4) {
          this.uploadLangTypeListing(
            subMenuItemDefault.content[3],
            subMenuItemLang.content[3]
          );
        }
        if (subMenuItemLang.type === 5) {
          this.uploadLangTypeFaq(
            subMenuItemDefault.content[4],
            subMenuItemLang.content[4]
          );
        }
        if (subMenuItemLang.type === 6) {
          this.uploadLangTypeContact(
            subMenuItemDefault.content[5],
            subMenuItemLang.content[5]
          );
        }
        if (subMenuItemLang.type === 7) {
          await this.uploadLangTypeEvents(
            subMenuItemDefault.content[6],
            subMenuItemLang.content[6]
          );
        }
        if (subMenuItemLang.type === 8) {
          await this.uploadLangTypeProducts(
            subMenuItemLang,
            subMenuItemDefault.content[7],
            subMenuItemLang.content[7]
          );
        }
        if (subMenuItemLang.type === 9) {
          this.uploadLangWeather(
            subMenuItemDefault.content[8],
            subMenuItemLang.content[8]
          );
        }
        if (subMenuItemLang.type === 10) {
          await this.uploadLangTypeMap(
            subMenuItemLang,
            subMenuItemDefault.content[9],
            subMenuItemLang.content[9]
          );
        }
        if (subMenuItemLang.type === 11) {
          this.uploadLangTypeDynamic(
            subMenuItemDefault.content[10],
            subMenuItemLang.content[10]
          );
        }
        if (subMenuItemLang.type === 12) {
          this.uploadLangTypeGallery(
            subMenuItemDefault.content[11],
            subMenuItemLang.content[11]
          );
        }
        if (subMenuItemLang.type === 13) {
          await this.uploadLangTypeTrails(subMenuItemLang);
        }
      }
    },
    uploadLangForm(formDefault, formLang) {
      if (formLang.show) {
        formLang.btn_name = this.checkUpload(formDefault.btn_name);
        for (let i = 0; i < formLang.pro.length; i++) {
          formLang.pro[i].title = this.checkUpload(formDefault.pro[i].title);
          for (let index = 0; index < formLang.pro[i].content.length; index++) {
            formLang.pro[i].content[index].label = this.checkUpload(
              formDefault.pro[i].content[index].label
            );
            formLang.pro[i].content[index].text = this.checkUpload(
              formDefault.pro[i].content[index].text
            );
          }
        }
      }
    },
    uploadLangSlider(sliderDefault, sliderLang) {
      for (let i = 0; i < sliderLang.length; i++) {
        sliderLang[i].title = this.checkUpload(sliderDefault[i].title);
        for (let index = 0; index < sliderLang[i].slides.length; index++) {
          sliderLang[i].slides[index].text = this.checkUpload(
            sliderDefault[i].slides[index].text
          );
          sliderLang[i].slides[index].text_link = this.checkUpload(
            sliderDefault[i].slides[index].text_link
          );
        }
      }
    },
    uploadLangTypeHtml(contentDefault, contentLang) {
      contentLang.content = this.checkUpload(contentDefault.content);
      this.uploadLangForm(contentDefault.form, contentLang.form);
      if (contentLang.active_slider) {
        this.uploadLangSlider(contentDefault.slider, contentLang.slider);
      }
    },
    uploadLangTypeListing(contentDefault, contentLang) {
      for (let i = 0; i < contentLang.content.listing.length; i++) {
        const listingGroupLang = contentLang.content.listing[i];
        const listingGroupDefault = contentDefault.content.listing[i];
        listingGroupLang.title = this.checkUpload(listingGroupDefault.title);
        for (let index = 0; index < listingGroupLang.content.length; index++) {
          const listingItemLang = listingGroupLang.content[index];
          const listingItemDefault = listingGroupDefault.content[index];
          listingItemLang.descr = this.checkUpload(listingItemDefault.descr);
          listingItemLang.name = this.checkUpload(listingItemDefault.name);
          listingItemLang.full_descr = this.checkUpload(
            listingItemDefault.full_descr
          );
          this.uploadLangForm(listingItemDefault.form, listingItemLang.form);
        }
      }
      if (contentLang.active_slider) {
        this.uploadLangSlider(contentDefault.slider, contentLang.slider);
      }
    },
    uploadLangTypeFaq(contentDefault, contentLang) {
      if (contentLang.active_slider) {
        this.uploadLangSlider(contentDefault.slider, contentLang.slider);
      }

      if (contentLang.motivation.show) {
        contentLang.motivation.content = this.checkUpload(
          contentDefault.motivation.content
        );
      }

      for (let i = 0; i < contentLang.content.length; i++) {
        const faqGroupLang = contentLang.content[i];
        const faqGroupDefault = contentDefault.content[i];
        faqGroupLang.title = this.checkUpload(faqGroupDefault.title);

        for (let index = 0; index < faqGroupLang.content.length; index++) {
          const faqItemLang = faqGroupLang.content[index];
          const faqItemDefault = faqGroupDefault.content[index];
          faqItemLang.answer = this.checkUpload(faqItemDefault.answer);
          faqItemLang.question = this.checkUpload(faqItemDefault.question);
        }
      }
    },
    uploadLangTypeContact(contentDefault, contentLang) {
      contentLang.text = this.checkUpload(contentDefault.text);
      contentLang.second_text = this.checkUpload(contentDefault.second_text);
    },
    async uploadLangTypeEvents(contentDefault, contentLang) {
      if (contentLang.active_slider) {
        this.checkSlider(contentDefault.slider, contentLang.slider);
      }
      await this.getEventCategories(this.$route.params.id);
      for (let i = 0; i < this.eventCategories.length; i++) {
        const eventCategory = this.eventCategories[i];
        eventCategory.text[this.selectedLang] = this.checkUpload(
          eventCategory.text["en"]
        );
        await this.editEventCategory(eventCategory);
        for (let index = 0; index < eventCategory.events.length; index++) {
          const event = eventCategory.events[index];
          event.full_description[this.selectedLang] =
            event.full_description["en"];
          event.name[this.selectedLang] = event.name["en"];
          await this.editEvent(event);
        }
      }
    },
    async uploadLangTypeProducts(menu, contentDefault, contentLang) {
      if (contentLang.active_slider) {
        this.uploadLangSlider(contentDefault.slider, contentLang.slider);
      }
      const payload = {
        hotel_id: this.$route.params.id,
        menu_id: menu.id,
      };
      await this.getCategoriesList(payload);
      for (let i = 0; i < this.categories.length; i++) {
        const productCategory = this.categories[i];
        productCategory.name[this.selectedLang] = this.checkUpload(
          productCategory.name["en"]
        );
        await this.editCategory(productCategory);
        for (
          let index = 0;
          index < productCategory.sub_categories.length;
          index++
        ) {
          const productSubcategory = productCategory.sub_categories[index];
          productSubcategory.name[this.selectedLang] = this.checkUpload(
            productSubcategory.name["en"]
          );
          await this.editSubCategory(productSubcategory);
          for (
            let productIndex = 0;
            productIndex < productSubcategory.products.length;
            productIndex++
          ) {
            const product = productSubcategory.products[productIndex];
            product.name[this.selectedLang] = this.checkUpload(
              product.name["en"]
            );
            product.small_description[this.selectedLang] = this.checkUpload(
              product.small_description["en"]
            );
            product.description[this.selectedLang] = this.checkUpload(
              product.description["en"]
            );
            await this.editProduct(product);
          }
        }
        for (
          let productIndex = 0;
          productIndex < productCategory.products.length;
          productIndex++
        ) {
          const product = productCategory.products[productIndex];
          product.name[this.selectedLang] = this.checkUpload(
            product.name["en"]
          );
          product.small_description[this.selectedLang] = this.checkUpload(
            product.small_description["en"]
          );
          product.description[this.selectedLang] = this.checkUpload(
            product.description["en"]
          );
          await this.editProduct(product);
        }
      }
    },
    uploadLangWeather(contentDefault, contentLang) {
      if (contentLang.active_slider) {
        this.uploadLangSlider(contentDefault.slider, contentLang.slider);
      }
      contentLang.content.bottom_content = this.checkUpload(
        contentDefault.content.bottom_content
      );
      contentLang.content.top_content = this.checkUpload(
        contentDefault.content.top_content
      );
    },
    async uploadLangTypeMap(menu, contentDefault, contentLang) {
      if (contentLang.active_slider) {
        this.uploadLangSlider(contentDefault.slider, contentLang.slider);
      }
      const payload = {
        hotel_id: this.$route.params.id,
        menu_id: menu.id,
      };
      await this.getMapData(payload);
      for (let i = 0; i < this.mapDataList.length; i++) {
        for (
          let index = 0;
          index < this.mapDataList[i].point_categories.length;
          index++
        ) {
          const pointCategory = this.mapDataList[i].point_categories[index];

          pointCategory.name[this.selectedLang] = this.checkUpload(
            pointCategory.name["en"]
          );
          await this.editPointCategory(pointCategory);

          for (
            let pointIndex = 0;
            pointIndex < pointCategory.points.length;
            pointIndex++
          ) {
            const point = pointCategory.points[pointIndex];
            point.description[this.selectedLang] = this.checkUpload(
              point.description["en"]
            );
            point.name[this.selectedLang] = this.checkUpload(point.name["en"]);
            await this.editPoint(point);
          }
        }
      }
    },
    uploadLangTypeGallery(contentDefault, contentLang) {
      this.uploadLangForm(contentDefault, contentLang);
      contentLang.full_descr = this.checkUpload(contentDefault.full_descr);
    },
    async uploadLangTypeTrails(menu) {
      const payload = {
        hotel_id: this.$route.params.id,
        menu_id: menu.id,
      };
      await this.getTrailList(payload);
      for (let i = 0; i < this.trailList.length; i++) {
        const trail = this.trailList[i];
        trail.description[this.selectedLang] = this.checkUpload(
          trail.description["en"]
        );
        trail.name[this.selectedLang] = this.checkUpload(trail.name["en"]);
        trail.small_description[this.selectedLang] = this.checkUpload(
          trail.small_description["en"]
        );
        await this.editTrail(trail);
        for (let index = 0; index < trail.route_points.length; index++) {
          const point = trail.route_points[index];
          point.description[this.selectedLang] = this.checkUpload(
            point.description["en"]
          );
          point.name[this.selectedLang] = this.checkUpload(point.name["en"]);
          point.small_description[this.selectedLang] = this.checkUpload(
            point.small_description["en"]
          );
          await this.editTrailPoint(point);
        }
      }
    },
    uploadLangTypeDynamic(contentDefault, contentLang) {
      for (let i = 0; i < contentLang.content.length; i++) {
        const dynamicMenu = contentLang.content[i];
        for (let index = 0; index < dynamicMenu.content.length; index++) {
          const dynamicMenuContent = dynamicMenu.content[index];
          if (dynamicMenuContent.type === 1) {
            this.checkTypeHtml(
              contentDefault.content[i].content[index],
              dynamicMenuContent
            );
          }
          if (dynamicMenuContent.type === 3) {
            this.checkTypeListing(
              contentDefault.content[i].content[index],
              dynamicMenuContent
            );
          }
          if (dynamicMenuContent.type === 4) {
            this.checkTypeGallery(
              contentDefault.content[i].content[index],
              dynamicMenuContent
            );
          }
        }
      }
    },

    changeShowUpload() {
      this.showUpload = !this.showUpload;
    },
    addToText(string) {
      if (typeof string !== "string") {

        return;
      }
      if (!this.checkArr.includes(string)) {
        this.checkArr.push(string);
        this.textArr.push({ en: string, new_lang: "" });
      }
    },
    checkBranding(branding) {
      /*BOTTOM MENU*/
      if (branding.bottomMenu.active) {
        for (let i = 0; i < branding.bottomMenu.items.length; i++) {
          const bottomMenuItem = branding.bottomMenu.items[i];
          this.addToText(bottomMenuItem.text["en"]);
        }
      }
      /*FOOTER TEXT*/
      if (branding.footer_text["en"]) {
        this.addToText(branding.footer_text["en"]);
      }

      /*FOOTER TITLE*/
      if (branding.footer_title["en"]) {
        this.addToText(branding.footer_title["en"]);
      }

      /*HEADER TITLE NO AUTH*/
      if (branding.title_no_auth["en"]) {
        this.addToText(branding.title_no_auth["en"]);
      }

      /*HEADER TITLE AUTH*/
      if (branding.title["en"]) {
        this.addToText(branding.title["en"]);
      }
      /* SLIDER */
      if (this.landing.advanced.global.active_slider) {
        for (let i = 0; i < branding.slider["en"].length; i++) {
          const sliderGroup = branding.slider["en"][i];
          this.addToText(sliderGroup.title);
          for (let index = 0; index < sliderGroup.slides.length; index++) {
            if (sliderGroup.slides[i].text) {
              this.addToText(sliderGroup.slides[i].text);
            }
            if (sliderGroup.slides[i].text_link) {
              this.addToText(sliderGroup.slides[i].text_link);
            }
          }
        }
      }
    },
    checkAdvanced(advanced) {
      if (advanced.global.cartPayments.text["en"]) {
        this.addToText(advanced.global.cartPayments.text["en"]);
      }
      if (advanced.global.page_404["en"].content) {
        this.addToText(advanced.global.page_404["en"].content);
      }
      if (advanced.global.underConstructor.show) {
        if (advanced.global.underConstructor.text["en"]) {
          this.addToText(advanced.global.underConstructor.text["en"]);
        }
      }
    },
    checkReviews(reviews) {
      const reviewsPro = reviews["en"];
      if (reviewsPro.btn_name) {
        this.addToText(reviewsPro.btn_name);
      }
      if (reviewsPro.header_text) {
        this.addToText(reviewsPro.header_text);
      }
      for (let i = 0; i < reviewsPro.pro.length; i++) {
        if (reviewsPro.pro[i].header) {
          this.addToText(reviewsPro.pro[i].header);
        }
        for (
          let index = 0;
          index < reviewsPro.pro[i].questions.length;
          index++
        ) {
          if (reviewsPro.pro[i].questions[index].name) {
            this.addToText(reviewsPro.pro[i].questions[index].name);
          }
        }
      }
      for (let i = 0; i < reviewsPro.questions.length; i++) {
        this.addToText(reviewsPro.questions[i].name);
      }
      for (let i = 0; i < reviewsPro.text_questions.length; i++) {
        this.addToText(reviewsPro.text_questions[i].name);
      }
      if (reviews.success_page["en"].content) {
        this.addToText(reviews.success_page["en"].content);
      }
      if (reviews.success_page["en"].second_content) {
        this.addToText(reviews.success_page["en"].second_content);
      }
      if (reviews.unsuccess_page["en"].content) {
        this.addToText(reviews.unsuccess_page["en"].content);
      }
      if (reviews.unsuccess_page["en"].second_content) {
        this.addToText(reviews.unsuccess_page["en"].second_content);
      }
    },
    checkOrderForm(orderForm) {
      const form = orderForm.content["en"];
      if (form.btn_name) {
        this.addToText(form.btn_name);
      }
      if (form.btn_second_name) {
        this.addToText(form.btn_second_name);
      }
      if (form.email_guest) {
        this.addToText(form.email_guest);
      }
      if (form.email_resident) {
        this.addToText(form.email_resident);
      }
      if (form.emptyCart) {
        this.addToText(form.emptyCart);
      }
      if (form.guest_phone) {
        this.addToText(form.guest_phone);
      }
      if (form.guest_special_request) {
        this.addToText(form.guest_special_request);
      }
      if (form.hotel_first_name_input) {
        this.addToText(form.hotel_first_name_input);
      }
      if (form.hotel_input) {
        this.addToText(form.hotel_input);
      }
      if (form.hotel_last_name_input) {
        this.addToText(form.hotel_last_name_input);
      }
      if (form.hotel_phone_input) {
        this.addToText(form.hotel_phone_input);
      }
      if (form.hotel_special_request) {
        this.addToText(form.message_header);
      }
      if (form.message_header_guest) {
        this.addToText(form.message_header_guest);
      }
      if (form.subject) {
        this.addToText(form.subject);
      }
      if (form.subject_guest) {
        this.addToText(form.subject_guest);
      }
      if (form.title) {
        this.addToText(form.title);
      }
      for (let i = 0; i < form.payment_methods_guest.length; i++) {
        this.addToText(form.payment_methods_guest[i].name);
      }
      for (let i = 0; i < form.payment_methods_resident.length; i++) {
        this.addToText(form.payment_methods_resident[i].name);
      }
    },
    checkOrder(order) {
      if (order.success_page["en"].content) {
        this.addToText(order.success_page["en"].content);
      }
      if (order.success_page["en"].second_content) {
        this.addToText(order.success_page["en"].second_content);
      }
      if (order.success_page["en"].table_content) {
        this.addToText(order.success_page["en"].table_content);
      }
    },
    checkForm(form) {
      if (form.show) {
        this.addToText(form.btn_name);
        for (let i = 0; i < form.pro.length; i++) {
          const formGroup = form.pro[i];
          if (formGroup.title) {
            this.addToText(formGroup.title);
          }
          for (let index = 0; index < formGroup.content.length; index++) {
            const formItem = formGroup.content[index];
            if (formItem.label) {
              this.addToText(formItem.label);
            }
            if (formItem.text) {
              this.addToText(formItem.text);
            }
          }
        }
      }
    },
    checkSlider(slider) {
      for (let i = 0; i < slider.length; i++) {
        const sliderGroup = slider[i];
        if (sliderGroup.title) {
          this.addToText(sliderGroup.title);
        }
        for (let index = 0; index < sliderGroup.slides.length; index++) {
          const sliderItem = sliderGroup.slides[index];
          if (sliderItem.text) {
            this.addToText(sliderItem.text);
          }
          if (sliderItem.text_link) {
            this.addToText(sliderItem.text_link);
          }
        }
      }
    },
    checkTypeListing(content) {
      for (let i = 0; i < content.content.listing.length; i++) {
        const listingGroup = content.content.listing[i];
        if (listingGroup.title) {
          this.addToText(listingGroup.title);
        }
        for (let index = 0; index < listingGroup.content.length; index++) {
          const listingItem = listingGroup.content[index];
          if (listingItem.descr) {
            this.addToText(listingItem.descr);
          }
          if (listingItem.full_descr) {
            this.addToText(listingItem.full_descr);
          }
          if (listingItem.name) {
            this.addToText(listingItem.name);
          }
          this.checkForm(listingItem.form);
        }
      }
      if (content.active_slider) {
        this.checkSlider(content.slider);
      }
    },
    checkTypeHtml(content) {
      if (content.content) {
        this.addToText(content.content);
      }
      this.checkForm(content.form);
      if (content.active_slider) {
        this.checkSlider(content.slider);
      }
    },
    checkTypeContact(content) {
      if (content.text) {
        this.addToText(content.text);
      }
      if (content.second_text) {
        this.addToText(content.second_text);
      }
    },
    checkFaq(content) {
      if (content.active_slider) {
        this.checkSlider(content.slider);
      }
      if (content.motivation.show) {
        this.addToText(content.motivation.content);
      }
      for (let i = 0; i < content.content.length; i++) {
        const faqGroup = content.content[i];
        if (faqGroup.title) {
          this.addToText(faqGroup.title);
        }
        for (let index = 0; index < faqGroup.content.length; index++) {
          const faqItem = faqGroup.content[index];
          if (faqItem.answer) {
            this.addToText(faqItem.answer);
          }
          if (faqItem.question) {
            this.addToText(faqItem.question);
          }
        }
      }
    },
    async checkMenu(menu) {
      for (let i = 0; i < menu.list.length; i++) {
        this.addToText(menu.list[i].text["en"]);
        const content = menu.list[i].content["en"];
        if (menu.list[i].type === 1) {
          this.checkTypeHtml(content[0]);
        }
        if (menu.list[i].type === 4) {
          this.checkTypeListing(content[3]);
        }
        if (menu.list[i].type === 5) {
          await this.checkSubmenu(content[4]);
        }
        if (menu.list[i].type === 6) {
          this.checkFaq(content[5]);
        }
        if (menu.list[i].type === 7) {
          this.checkTypeContact(content[6]);
        }
        if (menu.list[i].type === 8) {
          await this.checkEvents(content[7]);
        }
        if (menu.list[i].type === 9) {
          await this.checkProducts(menu.list[i], content[8]);
        }
        if (menu.list[i].type === 10) {
          this.checkWeather(content[9]);
        }
        if (menu.list[i].type === 11) {
          await this.checkMap(menu.list[i], content[10]);
        }
        if (menu.list[i].type === 12) {
          this.checkDynamic(content[11]);
        }
        if (menu.list[i].type === 13) {
          this.checkTypeGallery(content[12]);
        }
        if (menu.list[i].type === 14) {
          await this.checkTrails(menu.list[i]);
        }
        if (menu.list[i].type === 15) {
          this.checkRadio(content[14]);
        }
      }
    },
    async checkEvents(content) {
      if (content.active_slider) {
        this.checkSlider(content.slider);
      }
      await this.getEventCategories(this.$route.params.id);
      for (let i = 0; i < this.eventCategories.length; i++) {
        const eventCategory = this.eventCategories[i];
        if (eventCategory.text["en"]) {
          this.addToText(eventCategory.text["en"]);
        }
        for (let index = 0; index < eventCategory.events.length; index++) {
          const event = eventCategory.events[index];
          if (event.full_description["en"]) {
            this.addToText(event.full_description["en"]);
          }
          if (event.name["en"]) {
            this.addToText(event.name["en"]);
          }
        }
      }
    },
    async checkProducts(menu, content) {
      if (content.active_slider) {
        this.checkSlider(content.slider);
      }
      const payload = {
        hotel_id: this.$route.params.id,
        menu_id: menu.id,
      };
      await this.getCategoriesList(payload);
      for (let i = 0; i < this.categories.length; i++) {
        const productCategory = this.categories[i];
        if (productCategory.name["en"]) {
          this.addToText(productCategory.name["en"]);
        }
        for (
          let index = 0;
          index < productCategory.sub_categories.length;
          index++
        ) {
          const productSubcategory = productCategory.sub_categories[index];
          if (productSubcategory.name["en"]) {
            this.addToText(productSubcategory.name["en"]);
          }
          for (
            let productIndex = 0;
            productIndex < productSubcategory.products.length;
            productIndex++
          ) {
            const product = productSubcategory.products[productIndex];
            if (product.name["en"]) {
              this.addToText(product.name["en"]);
            }
            if (product.small_description["en"]) {
              this.addToText(product.small_description["en"]);
            }
            if (product.description["en"]) {
              this.addToText(product.description["en"]);
            }
          }
        }
        for (
          let productIndex = 0;
          productIndex < productCategory.products.length;
          productIndex++
        ) {
          const product = productCategory.products[productIndex];
          if (product.name["en"]) {
            this.addToText(product.name["en"]);
          }
          if (product.small_description["en"]) {
            this.addToText(product.small_description["en"]);
          }
          if (product.description["en"]) {
            this.addToText(product.description["en"]);
          }
        }
      }
    },
    checkWeather(content) {
      if (content.active_slider) {
        this.checkSlider(content.slider);
      }
      if (content.content.bottom_content) {
        this.addToText(content.content.bottom_content);
      }
      if (content.content.top_content) {
        this.addToText(content.content.top_content);
      }
    },
    async checkMap(menu, content) {
      if (content.active_slider) {
        this.checkSlider(content.slider);
      }
      const payload = {
        hotel_id: this.$route.params.id,
        menu_id: menu.id,
      };
      await this.getMapData(payload);
      for (let i = 0; i < this.mapDataList.length; i++) {
        for (
          let index = 0;
          index < this.mapDataList[i].point_categories.length;
          index++
        ) {
          const pointCategory = this.mapDataList[i].point_categories[index];
          if (pointCategory.name["en"]) {
            this.addToText(pointCategory.name["en"]);
          }
          for (
            let pointIndex = 0;
            pointIndex < pointCategory.points.length;
            pointIndex++
          ) {
            const point = pointCategory.points[pointIndex];
            if (point.description["en"]) {
              this.addToText(point.description["en"]);
            }
            if (point.name["en"]) {
              this.addToText(point.name["en"]);
            }
          }
        }
      }
    },
    checkDynamic(content) {
      for (let i = 0; i < content.content.length; i++) {
        const dynamicMenu = content.content[i];
        for (let index = 0; index < dynamicMenu.content.length; index++) {
          const dynamicMenuContent = dynamicMenu.content[index];
          if (dynamicMenuContent.type === 1) {
            this.checkTypeHtml(dynamicMenuContent);
          }
          if (dynamicMenuContent.type === 3) {
            this.checkTypeListing(dynamicMenuContent);
          }
          if (dynamicMenuContent.type === 4) {
            this.checkTypeGallery(dynamicMenuContent);
          }
        }
      }
    },
    checkTypeGallery(content) {
      this.checkForm(content.form);
      if (content.full_desc) {
        this.addToText(content.full_desc);
      }
    },
    async checkTrails(menu) {
      const payload = {
        hotel_id: this.$route.params.id,
        menu_id: menu.id,
      };
      await this.getTrailList(payload);
      for (let i = 0; i < this.trailList.length; i++) {
        const trail = this.trailList[i];
        if (trail.description["en"]) {
          this.addToText(trail.description["en"]);
        }
        if (trail.name["en"]) {
          this.addToText(trail.name["en"]);
        }
        if (trail.small_description["en"]) {
          this.addToText(trail.small_description["en"]);
        }
        for (let index = 0; index < trail.route_points.length; index++) {
          const point = trail.route_points[index];
          if (point.description["en"]) {
            this.addToText(point.description["en"]);
          }
          if (point.name["en"]) {
            this.addToText(point.name["en"]);
          }
          if (point.small_description["en"]) {
            this.addToText(point.small_description["en"]);
          }
        }
      }
    },
    checkRadio(content) {
      for (let i = 0; i < content.content.length; i++) {
        const radioGroup = content.content[i];
        if (radioGroup.title) {
          this.addToText(radioGroup.title);
        }
        for (let index = 0; index < radioGroup.stations.length; index++) {
          const radioStation = radioGroup.stations[index];
          if (radioStation.description) {
            this.addToText(radioStation.description);
          }
          if (radioStation.title) {
            this.addToText(radioStation.title);
          }
        }
      }
    },
    async checkSubmenu(content) {
      if (content.active_slider) {
        this.addToText(content.slider);
      }
      for (let i = 0; i < content.content.menu.length; i++) {
        const subMenuItem = content.content.menu[i];
        if (subMenuItem.name) {
          this.addToText(subMenuItem.name);
        }
        if (subMenuItem.type === 1) {
          this.checkTypeHtml(subMenuItem.content[0]);
        }
        if (subMenuItem.type === 4) {
          this.checkTypeListing(subMenuItem.content[3]);
        }
        if (subMenuItem.type === 5) {
          this.checkFaq(subMenuItem.content[4]);
        }
        if (subMenuItem.type === 6) {
          this.checkTypeContact(subMenuItem.content[5]);
        }
        if (subMenuItem.type === 7) {
          await this.checkEvents(subMenuItem.content[6]);
        }
        if (subMenuItem.type === 8) {
          await this.checkProducts(subMenuItem, subMenuItem.content[7]);
        }
        if (subMenuItem.type === 9) {
          this.checkWeather(subMenuItem.content[8]);
        }
        if (subMenuItem.type === 10) {
          await this.checkMap(subMenuItem, subMenuItem.content[9]);
        }
        if (subMenuItem.type === 11) {
          this.checkDynamic(subMenuItem.content[10]);
        }
        if (subMenuItem.type === 12) {
          this.checkTypeGallery(subMenuItem.content[11]);
        }
        if (subMenuItem.type === 13) {
          await this.checkTrails(subMenuItem);
        }
        if (subMenuItem.type === 14) {
          this.checkRadio(subMenuItem.content[13]);
        }
      }
    },
    async downloadLangs() {
      for (let key in this.landing) {
        if (key === "advanced") {
          this.checkAdvanced(this.landing[key]);
        }
        if (key === "branding") {
          this.checkBranding(this.landing[key]);
        }
        if (key === "reviews") {
          this.checkReviews(this.landing[key]);
        }
        if (key === "order_form") {
          this.checkOrderForm(this.landing[key]);
        }
        if (key === "order") {
          this.checkOrder(this.landing[key]);
        }
        if (key === "menu") {
          await this.checkMenu(this.landing[key]);
        }
      }
      this.downloadFile();
    },
    downloadFile() {
      let csv = this.$papa.unparse(this.textArr);
      let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.download =
        "english_data " + this.$moment().format("DD_MM_yyyy_HH_mm") + ".csv";
      link.href = window.URL.createObjectURL(csvData);
      link.dataset.downloadurl = ["text/csv", link.download, link.href].join(
        ":"
      );
      const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      link.dispatchEvent(evt);
      link.remove();
    },
    async onFileChange(file) {
      this.fileUpload = true;
      await this.$papa.parse(file, {
        complete: async (result) => {
          this.wordsArr = result.data;
        },
      });
    },
  },
};
</script>

<style scoped></style>
